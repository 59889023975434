import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import FoodBankIcon from "@mui/icons-material/FoodBank";
import MopedIcon from "@mui/icons-material/Moped";
import "./paymentPage.scss";
import { Helmet } from "react-helmet";

const createData = (city, price, freeFrom, minimalOrder) => {
  return { city, price, freeFrom, minimalOrder };
};

const rows = [
  createData("м. Горішні Плавні", 50, 450, 300),
  createData("Піддубне", 60, 650, 300),
  createData("Низи", 70, 700, 300),
];

const PaymentPage = () => {
  return (
    <>
      <Helmet>
        <title>Sakana Sushi Оплата</title>
        <meta
          name="description"
          content="Також доставляємо в Піддубне, Низи."
        />
      </Helmet>
      <div className="payment-info">
        <h2>Умови доставки</h2>
        <p className="phone">
          <ContactPhoneIcon fontSize="large" sx={{ color: "#1976d2" }} />
          <a href="tel:+380970941919">+38 097 094 19 19</a>
        </p>
        <div className="work-hours">
          <div className="left">
            <FoodBankIcon fontSize="large" sx={{ color: "#1976d2" }} />
          </div>
          <div className="right">
            <span>Години роботи: </span>
            <div>
              <span className="open">10:00</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="close">21:00</span>
            </div>
            <span>Без вихідних</span>
          </div>
        </div>
        <h3 className="order_info">
          Доставка <MopedIcon sx={{ color: "yellow" }} />: &nbsp; здійснюється
          до під'їзду
        </h3>
        <TableContainer
          component={Paper}
          sx={{
            maxWidth: 450,
            bgcolor: "transparent",
            color: "rgb(255, 255, 255)",
          }}
        >
          <Table aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Населений пункт</TableCell>
                <TableCell align="center">Ціна доставки</TableCell>
                <TableCell align="center">Безкоштовно від</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.city}>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{ fontWeight: 600 }}
                  >
                    {row.city}
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: 20 }}>
                    {row.price} ₴
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: 20 }}>
                    {row.freeFrom} ₴
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={3} align="center">
                  Замовлення на доставку приймаються до 20:30
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  Замовлення на самовиніс приймаються до 20:45
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <p className="info-time">
          Час доставки - від 60 до 120 хвилин (точний час уточнюйте у
          операторів)
        </p>
        <p className="pre-order">
          При оформленні замовлення на конкретну годину, можливе корегування за
          часом до 30 хвилин.
        </p>
      </div>
    </>
  );
};

export default PaymentPage;
