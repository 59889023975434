import React from "react";

function Sticks() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="#fff"
      version="1.1"
      viewBox="0 0 50.438 50.438"
      xmlSpace="preserve"
    >
      <path d="M37.676 17.771l-2.174-2.645c-.208-.252-.589-.298-.832-.079-4.729 4.268-24.78 30.471-26.547 35.11-.116.305.062.378.284.138L37.65 18.663a.698.698 0 00.026-.892zM5.469 48.649c.083.316.285.356.466.084l24.054-35.93a.479.479 0 00-.213-.73l-3.519-1.553a.664.664 0 00-.831.278c-6.823 12.169-13.565 24.18-20.26 36.217-.149.268.069.741.303 1.634zM32.18.086a.573.573 0 00-.8.203c-.763 1.319-1.417 2.441-2.059 3.571-2.394 4.217-2.486 4.383 1.852 6.134a.76.76 0 00.878-.271l4.269-6.396a.566.566 0 00-.175-.802L32.18.086zm8.745 6.68c-5.289 5.098-5.448 5.914-1.28 8.88a.694.694 0 00.886-.094l4.635-5.012a.577.577 0 00-.04-.827L41.795 6.75a.65.65 0 00-.87.016z"></path>
    </svg>
  );
}

export default Sticks;
