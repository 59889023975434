import { useEffect, useState } from "react";
import { db } from "../../../firebase/firebase";
import { collection, getDocs } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getWeeklyPromo, getPromoItems } from "../../../actions";
import { WeeklyProm } from "../PromotionsPage/PromotionsPage";
import "./mainPage.scss";
import Logo from "../../../assets/Logo";
import Preloader from "../../Preloader/Preloader";

const MainPage = () => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const data = createSelector(
    (state) => state.data,
    (data) => {
      return { data: data };
    }
  );

  const productsData = useSelector(data);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setOpen(false);
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, [open]);

  useEffect(() => {
    if (productsData.data.weekPromo === null) {
      setLoading(true);
      const getAllData = () => {
        getDocs(collection(db, "weekly_promo")).then((doc) =>
          doc.forEach((doc) => {
            dispatch(getWeeklyPromo(doc.data()));
          })
        );
      };
      getAllData();
    } else {
      return;
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (productsData.data.promoItems.length === 0) {
      setLoading(true);
      const getAllData = () => {
        getDocs(collection(db, "discount_items")).then((doc) =>
          doc.forEach((doc) => {
            dispatch(getPromoItems(doc.data()));
          })
        );
      };
      getAllData();
    } else {
      return;
    }
    setLoading(false);
  }, []);

  return (
    <>
      {open && (
        <div className="preloader">
          <Preloader />
        </div>
      )}
      <div className="main-page">
        {!open && (
          <div className="logo_loader">
            <Logo />
          </div>
        )}

        <div className="main_discount">
          <div className="discount_banner ">
            {productsData.data.weekPromo !== null && (
              <WeeklyProm
                date={
                  productsData.data.weekPromo && productsData.data.weekPromo.end
                }
                name={
                  productsData.data.weekPromo &&
                  productsData.data.weekPromo.name
                }
                discount={
                  productsData.data.weekPromo &&
                  productsData.data.weekPromo.discount
                }
                image={
                  productsData.data.weekPromo &&
                  productsData.data.weekPromo.image
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPage;
