import { useState } from "react";
import { Fragment } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import logo from "../../../assets/Logo_suschi.png";
import MenuIcon from "@mui/icons-material/Menu";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import drinks from "../../../assets/drinks.svg";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import "./menuNavigation.scss";
import { Link, NavLink } from "react-router-dom";
import { menuLinks } from "./links";

const MenuNavigation600 = (props) => {
  const [state, setState] = useState({
    left: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      className="side_drawer600"
      role="presentation"
      sx={{
        bgcolor: "#121212",
        height: "100%",
        background: "#121212",
      }}
    >
      <div className="logo_wrapper">
        <Link to="/" className="logo_link">
          <img src={logo} alt="logo" height={100} width={100} />
        </Link>
      </div>

      <div className="nav_side">
        <Accordion
          elevation={1}
          sx={{
            bgcolor: "#121212",
            borderRadius: 0,
            width: "110%",
          }}
        >
          <AccordionSummary
            expandIcon={<MenuOpenIcon sx={{ color: "#fff" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ color: "white" }}>Меню</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              borderRadius: 0,
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            {menuLinks.map((menu) => (
              <Fragment key={menu.name}>
                <button
                  className="menu_button"
                  onClick={toggleDrawer(anchor, false)}
                >
                  <NavLink
                    to={menu.to}
                    className="links_with_ico"
                    style={({ isActive }) => {
                      return {
                        color: isActive ? "red" : "inherit",
                      };
                    }}
                  >
                    {menu.name}&nbsp;
                    {menu.isNew ? <span style={{color: "black"}} className="new">New</span> : null}
                    <span className="ico">{menu.ico}</span>
                  </NavLink>
                </button>
                <Divider color={"grey"} />
              </Fragment>
            ))}
            <button
              className="menu_button"
              onClick={toggleDrawer(anchor, false)}
            >
              <NavLink
                to="/drinks"
                className="links_with_ico"
                style={({ isActive }) => {
                  return {
                    color: isActive ? "red" : "inherit",
                  };
                }}
              >
                Напої{" "}
                <span className="ico">
                  <img src={drinks} alt="" height={20} width={20} />
                </span>
              </NavLink>
            </button>
          </AccordionDetails>
        </Accordion>
        <Button
          elevation={1}
          onClick={toggleDrawer(anchor, false)}
          sx={{ color: "#fff" }}
        >
          <Link to="/">Головна</Link>
        </Button>
        <Button
          elevation={1}
          onClick={toggleDrawer(anchor, false)}
          sx={{ color: "#fff" }}
        >
          <Link to="/promo">Акції</Link>
        </Button>

        <Button
          elevation={1}
          onClick={toggleDrawer(anchor, false)}
          sx={{ color: "#fff" }}
        >
          <Link to="/paymentinfo">Оплата та доставка</Link>
        </Button>

        <Button
          elevation={1}
          onClick={toggleDrawer(anchor, false)}
          sx={{ color: "#fff" }}
        >
          <Link to="/contacts">Контакти</Link>
        </Button>
      </div>
    </Box>
  );
  return (
    <div className="button600">
      <button
        onClick={toggleDrawer("left", true)}
        className="active-button__menu"
      >
        <MenuIcon />
      </button>
      <SwipeableDrawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        {list("left")}
      </SwipeableDrawer>
    </div>
  );
};

export default MenuNavigation600;
