export const menuLinks = [
  { name: "Сети", to: "/sets", ico: "V" },
  { name: "Авторські роли", to: "/classic_sushi", ico: "@" },
  { name: "Авокадо роли", to: "/avocado_rolls", ico: "h" },
  { name: "Боніто роли", to: "/bonito", ico: "@" },
  { name: "Вега Роли", to: "/vegan", ico: "@" },
  { name: "Гарячі роли", to: "/hot_rolls", ico: "@" },
  { name: "Гриль роли", to: "/grill_rolls", ico: "@" },
  { name: "Дракони", to: "/dragons", ico: "h" },
  { name: "Каліфорнії", to: "/california", ico: "j" },
  { name: "Макі", to: "/maki", ico: "@" },
  { name: "Роли без рису", to: "/no_rice", ico: "@" },
  {
    name: "Суші бургер/хот-дог",
    to: "/burger_sushi&hotdog",
    ico: "@",
    isNew: true,
  },
  { name: "Філадельфія", to: "/philadelphia", ico: "h" },
  { name: "Солодкі роли", to: "/solodki_rolls", ico: "Q", isNew: true },
  { name: "Чіз роли", to: "/cheese_rolls", ico: "h" },
  { name: "Гункани / Нігірі", to: "/guncans", ico: "@" },
  { name: "Салати / Боули", to: "/salad_bowley", ico: "b" },
  { name: "Соуси / Топінги", to: "/toping_souse", ico: "1" },
];
