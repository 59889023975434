import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import "./shoppingCart.scss";
import CartItems from "./ShoppingCartItem";
import {
  deleteOneItem,
  addOneItem,
  removeItem,
  addItemToCart,
} from "../../actions";
import ItemCard from "../Pages/MenuPages/ItemCard/ItemCard";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const ShoppingCart = (props) => {
  const [state, setState] = useState({
    right: false,
  });
  const dispatch = useDispatch();

  const cartData = createSelector(
    (state) => state.basket,
    (state) => state.data,
    (basket, data) => {
      return {
        basket: basket.products,
        data,
      };
    }
  );

  const items = useSelector(cartData);

  const onAddOneItem = (id) => {
    dispatch(addOneItem(id));
  };

  const onDeleteOneItem = (id) => {
    dispatch(deleteOneItem(id));
  };

  const onRemoveItem = (id) => {
    dispatch(removeItem(id));
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const onAdd = (id) => {
    const item = items.data.promoItems.find((item) => item.id === id);
    dispatch(addItemToCart(item));
  };

  const fallBack = (
    <div className="cart_fallback">
      <h3>Ваш кошик пустий</h3>
    </div>
  );

  const list = () => (
    <Box
      sx={{
        width: props.width <= 850 ? "100vw" : 850,
        height: "100%",
        background: "#121212",
      }}
      role="presentation"
      className="cart_box"
    >
      <div className="cart_head">
        <h3>Ваше замовлення</h3>
        <button onClick={toggleDrawer("right", false)}>
          <CloseRoundedIcon sx={{ color: "#fff" }} />
        </button>
      </div>
      {items.data.promoItems.length !== 0 && (
        <div className="promo_cart">
          <h3>Акційні пропозиції</h3>
          <div className="promo_cart_items">
            {items.data.promoItems.map((rol, i) => (
              <ItemCard
                {...rol}
                key={rol.id}
                onAdd={() => onAdd(rol.id)}
                className={"cart_class_name"}
              />
            ))}
          </div>
        </div>
      )}

      {items.basket.length === 0 && fallBack}

      {items.basket.length !== 0 && (
        <>
          <h3 style={{ padding: "10px", textAlign: "center" }}>
            Обрані товари
          </h3>
          <TableContainer component={Paper}>
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Зображення</TableCell>
                  <TableCell align="center">Назва</TableCell>
                  <TableCell align="center">Ціна</TableCell>
                  <TableCell align="center">Кількість</TableCell>
                  {props.width <= 600 ? null : (
                    <>
                      <TableCell align="center">Ціна всього</TableCell>
                      <TableCell align="center">Видилити</TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <CartItems
                  items={items.basket}
                  deleteItem={onRemoveItem}
                  addOneItem={onAddOneItem}
                  deleteOneItem={onDeleteOneItem}
                  width={props.width}
                  onClose={toggleDrawer("right", false)}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );

  return (
    <div>
      <span onClick={toggleDrawer("right", true)}>
        <img src={props.data} alt="cart" />
      </span>
      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        {list("right")}
      </SwipeableDrawer>
    </div>
  );
};

export default ShoppingCart;
