import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 5,
  color: "#fff",
  boxShadow: 24,
  zIndex: 5,
  p: 4,
};

export const ModalOrder = ( props ) => {
  const [_, setOpen] = useState();
  const onCloseModel = () => {
    setOpen( false );
  };
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={onCloseModel}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Ваше замовлення
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {props.error
                ? props.error
                : "Дякуємо за Ваше замовлення, очікуйте на дзвінок оператора"}
            </Typography>
            <button
              onClick={() => {
                props.close();
                !props.error && navigate( "/" );
              }}
              style={{
                marginTop: 25,
                width: "100%",
                padding: "10px",
                border: 0,
                borderRadius: 4,
                background: "#ff0000",
                color: "white",
                fontSize: 18,
                cursor: "pointer",
              }}
            >
              Закрити
            </button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};


export const SomeComponent = ( props ) => {
  const [_, setOpen] = useState();
  const onCloseModel = () => {
    setOpen( false );
  };
  const navigate = useNavigate();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={onCloseModel}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              <span style={{ color: "red" }}>
              ВЕЛИКЕ НАЇДАЛОВО
              </span>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              «Велике наїдалово» - єдина знижка -20% на всі страви у меню!😱🍣 <br/>
              Акція діє до 31 серпня 2023 рокую <br/>
              При замовленні страви з акційного меню, знижка -20% на всі страви у меню! <br/>
              Акції не сумуються. <br/>
            </Typography>
            <button
              onClick={() => {
                props.close();
                !props.error && navigate( "/promo/weekly_promo" );
              }}
              style={{
                marginTop: 25,
                width: "100%",
                padding: "10px",
                border: 0,
                borderRadius: 4,
                background: "#ff0000",
                color: "white",
                fontSize: 18,
                cursor: "pointer",
              }}
            >
              Деталі Акції
            </button>
            <button
              onClick={() => {
                props.close();
              }}
              style={{
                marginTop: 25,
                width: "100%",
                padding: "10px",
                border: 0,
                borderRadius: 4,
                background: "#ff0000",
                color: "white",
                fontSize: 18,
                cursor: "pointer",
              }}
            >
              Закрити
            </button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}