import { ThemeProvider, createTheme } from "@mui/material/styles";
import MainPage from "../Pages/MainPage/MainPage";
import { lazy, Suspense, useState, useEffect } from "react";
import Spinner from "../Spinner";
import PaymentPage from "../Pages/PaymentPage/PaymentPage";
import Header from "../Header/Header";
import ActionMenu from "../Pages/MainPage/ActionMenu/ActionMenu";
import ContactsPage from "../Pages/ContactsPage/ContactPage";
import PromotionsPage from "../Pages/PromotionsPage/PromotionsPage";
import OrderPage from "../Pages/OrderPage/OrderPage";
import ArrowUp from "../Pages/MainPage/ActionMenu/ArrowUp";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import MetaPixel from "../../utils/MetaPixel";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./app.scss";

const AvocadoRollsPage = lazy(() =>
  import("../Pages/MenuPages/AvocadoRollsPage")
);
const Guncans = lazy(() => import("../Pages/MenuPages/GuncansPage"));
const BonitoPage = lazy(() => import("../Pages/MenuPages/BonitoPage"));
const CaliforniaPage = lazy(() => import("../Pages/MenuPages/CaliforniaPage"));
const CheeseRollsPage = lazy(() =>
  import("../Pages/MenuPages/CheeseRollsPage")
);
const ClassicSushi = lazy(() => import("../Pages/MenuPages/AuthorsRolls"));
const Maki = lazy(() => import("../Pages/MenuPages/MakiPage"));
const DragonsPage = lazy(() => import("../Pages/MenuPages/DragonsPage"));
const DrinksPage = lazy(() => import("../Pages/MenuPages/DrinksPage"));
const FutomakiPage = lazy(() => import("../Pages/MenuPages/FutomakiPage"));
const GrillRollsPage = lazy(() => import("../Pages/MenuPages/GrillRollsPage"));
const PhiladelphiaPage = lazy(() =>
  import("../Pages/MenuPages/PhiladelphiaPage")
);
const SaladPage = lazy(() => import("../Pages/MenuPages/SaladPage"));
const SetsPage = lazy(() => import("../Pages/MenuPages/SetsPage"));
const ToppingPage = lazy(() => import("../Pages/MenuPages/ToppingPage"));
const WeeklyPromo = lazy(() =>
  import("../Pages/PromotionsPage/WeeklyPromo/WeeklyPromo")
);

const HotRolls = lazy(() => import("../Pages/MenuPages/HotRolls"));
const BurgerSushi = lazy(() => import("../Pages/MenuPages/BurgerSushi"));
const NoRiceRolls = lazy(() => import("../Pages/MenuPages/NoRiceRolls"));
const VeganRolls = lazy(() => import("../Pages/MenuPages/VeganRolls"));

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const routes = [
  {
    path: "/",
    name: "Main",
    element: <MainPage />,
  },
  {
    path: "/guncans",
    name: "Guncans",
    element: <Guncans />,
  },
  {
    path: "/hot_rolls",
    name: "HotRolls",
    element: <HotRolls />,
  },
  {
    path: "/vegan",
    name: "VeganRolls",
    element: <VeganRolls />,
  },
  {
    path: "/no_rice",
    name: "NoRiceRolls",
    element: <NoRiceRolls />,
  },
  {
    path: "/burger_sushi&hotdog",
    name: "BurgerSushi",
    element: <BurgerSushi />,
  },
  {
    path: "/maki",
    name: "Maki",
    element: <Maki />,
  },
  {
    path: "/promo/weekly_promo",
    name: "WeeklyPromo",
    element: <WeeklyPromo />,
  },
  {
    path: "/paymentinfo",
    name: "PaymentPage",
    element: <PaymentPage />,
  },
  {
    path: "/promo",
    name: "PromotionsPage",
    element: <PromotionsPage />,
  },
  {
    path: "/contacts",
    name: "ContactsPage",
    element: <ContactsPage />,
  },
  {
    path: "/sets",
    name: "Sets",
    element: <SetsPage />,
  },

  {
    path: "/avocado_rolls",
    name: "AvocadoRollsPage",
    element: <AvocadoRollsPage />,
  },
  {
    path: "/bonito",
    name: "BonitoPage",
    element: <BonitoPage />,
  },
  {
    path: "/california",
    name: "CaliforniaPage",
    element: <CaliforniaPage />,
  },

  {
    path: "/cheese_rolls",
    name: "CheeseRollsPage",
    element: <CheeseRollsPage />,
  },
  {
    path: "/classic_sushi",
    name: "ClassicSushi",
    element: <ClassicSushi />,
  },
  {
    path: "/dragons",
    name: "DragonsPage",
    element: <DragonsPage />,
  },
  {
    path: "/drinks",
    name: "DrinksPAge",
    element: <DrinksPage />,
  },
  {
    path: "/solodki_rolls",
    name: "FutomakiPage",
    element: <FutomakiPage />,
  },
  {
    path: "/grill_rolls",
    name: "GrillRollsPage",
    element: <GrillRollsPage />,
  },
  {
    path: "/philadelphia",
    name: "PhiladelphiaPage",
    element: <PhiladelphiaPage />,
  },
  {
    path: "/salad_bowley",
    name: "SaladPage",
    element: <SaladPage />,
  },
  {
    path: "/toping_souse",
    name: "ToppingPage",
    element: <ToppingPage />,
  },

  {
    path: "/order",
    name: "Order",
    element: <OrderPage />,
  },
];

const TRACKING_ID = "G-48QFHL2B56";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <BrowserRouter>
      <Header />

      <ThemeProvider theme={darkTheme}>
        <MetaPixel />
        <Suspense
          fallback={
            <div className="page_fallback">
              <Spinner />
            </div>
          }
        >
          <main>
            <Helmet>
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-48QFHL2B56"
              ></script>

              <title>Sakana Sushi - доставка суші в м. Горішні Плавні</title>
              <meta
                name="description"
                content="Суші та роли - смачно, недорого, якісно! Є безкоштовна доставка"
              />
              <meta
                name="description"
                content="Знижки іменинникам та військовим, конкурси та бонуси."
              />
              <meta
                name="description"
                content="Також доставляємо в Піддубне, Низи."
              />
              <link rel="canonical" href="https://sakana-sushi.com/" />
            </Helmet>
            <ArrowUp visible={visible} />
            <ActionMenu />
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.name}
                  path={route.path}
                  element={route.element}
                />
              ))}
            </Routes>
          </main>
        </Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
