import { useState } from "react";
import SpeedDial from "@mui/material/SpeedDial";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Backdrop from "@mui/material/Backdrop";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import "./actionMenu.scss";

const actions = [
  {
    icon: <TelegramIcon sx={{ color: "#2AABEE" }} />,
    name: "Telegram",
    to: "https://t.me/sakana_hp",
  },
  {
    icon: <InstagramIcon sx={{ color: "#fd5949" }} />,
    name: "Instagram",
    to: "https://www.instagram.com/sakana.sushi.hp/",
  },
];

const ActionMenu = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="action_menu">
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="Social media"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
        icon={<LinkRoundedIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={
              <a
                href={action.to}
                target={"_blank"}
                rel="noreferrer"
                className="speed_link"
              >
                {action.icon}
              </a>
            }
            tooltipTitle={action.name}
            tooltipOpen
            onClick={handleClose}
          />
        ))}
        <SpeedDialAction
          icon={
            <a
              href="https://www.facebook.com/sakana.sushi.hp"
              target={"_blank"}
              rel="noreferrer"
              className="speed_link"
            >
              <FacebookRoundedIcon sx={{ color: "#3b5998" }} />
            </a>
          }
          tooltipTitle={"Facebook"}
          tooltipOpen
          onClick={() => setOpen(false)}
        />
        <SpeedDialAction
          icon={
            <a href="tel:+380970941919" className="speed_link">
              <LocalPhoneIcon sx={{ color: "#2196f3" }} />
            </a>
          }
          tooltipTitle={"+380970941919"}
          tooltipOpen
          onClick={() => setOpen(false)}
        />
      </SpeedDial>
    </div>
  );
};

export default ActionMenu;
