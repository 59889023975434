import { useRef, useEffect } from "react";
import "./contactsPage.scss";
import shop from "../../../assets/shop.jpg";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import Viber from "../../../assets/Viber";
import { Helmet } from "react-helmet";

const ContactsPage = () => {
  const mapRef = useRef();
  const center = {
    lat: 49.0076093,
    lng: 33.6338707,
  };
  const zoom = 16;

  useEffect(() => {
    try {
      const map = new window.google.maps.Map(mapRef.current, {
        center: {
          lat: 49.0076093,
          lng: 33.6338707,
        },
        zoom: zoom,
        styles: [
          { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
          {
            elementType: "labels.text.stroke",
            stylers: [{ color: "#242f3e" }],
          },
          { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#263c3f" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#6b9a76" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#38414e" }],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#212a37" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9ca5b3" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#746855" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#1f2835" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#f3d19c" }],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#2f3948" }],
          },
          {
            featureType: "transit.station",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#17263c" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#515c6d" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#17263c" }],
          },
        ],
      });

      new window.google.maps.Marker({ position: center, map: map });
    } catch (e) {
      console.log(e);
    }
  }, [center, zoom]);

  return (
    <>
      <Helmet>
        <title>Контакти</title>
        <meta
          name="description"
          content="м. Горішні Плавні  просп. Героїв Дніпра, 36"
        />
      </Helmet>
      <div className="contacts">
        <h2>Контакти</h2>
        <div className="contacts_list">
          <div className="left">
            <p className="phone">
              Телефон <a href="tel:+380970941919">+38 097 094 19 19</a>
            </p>
          </div>
          <div className="right">
            <p>
              Ми у мережі
              <a
                href="https://www.facebook.com/sakana.sushi.hp"
                target={"_blank"}
                rel="noreferrer"
              >
                <FacebookRoundedIcon
                  fontSize="large"
                  sx={{ color: "#3b5998" }}
                />
              </a>
              <a
                href="https://www.instagram.com/sakana.sushi.hp/"
                target={"_blank"}
                rel="noreferrer"
              >
                <InstagramIcon fontSize="large" sx={{ color: "#f05939" }} />
              </a>
              <a
                href="https://t.me/sakana_hp"
                target={"_blank"}
                rel="noreferrer"
              >
                <TelegramIcon fontSize="large" sx={{ color: "#3b5998" }} />
              </a>
              <a href="viber://chat?number=380997108526">
                <Viber />
              </a>
            </p>
          </div>
        </div>
        <div className="address">
          <h3>Адреса</h3>
          <span>м. Горішні Плавні</span>
          <span>просп. Героїв Дніпра, 36</span>
        </div>

        <div className="map_image_info">
          <div className="place_photo">
            <img src={shop} alt="place" />
          </div>

          <div ref={mapRef} className="map"></div>
        </div>
      </div>
    </>
  );
};

export default ContactsPage;
