import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCallback, useEffect, useState } from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Spinner from "../../../Spinner";
import ImageViewer from "react-simple-image-viewer";
import "./itemCard.scss";

const ItemCard = (props) => {
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [add, setAdd] = useState(false);
  const currentImageSrc = [props.image];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  useEffect(() => {
    let timer;
    if (add) {
      timer = setInterval(() => {
        setAdd(false);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [add]);
  return (
    <div className={`menu_item ${props.className}`}>
      {props.discount && (
        <span className="discount">{"Знижка " + props.discount + "%"}</span>
      )}
      {props.isNew ? <span className="new">New</span> : null}
      {loading && <Spinner />}
      <img
        src={props.image}
        onClick={ () => openImageViewer(0) }
        onLoad={() => setLoading(false)}
        alt="sets"
        style={loading ? { height: 0, width: 0 } : {cursor: "pointer"}}
      />
      {isViewerOpen && (
        <ImageViewer
          src={ currentImageSrc }
          currentIndex={ currentImage }
          disableScroll={ false }
          closeOnClickOutside={ true }
          onClose={ closeImageViewer }
          backgroundStyle={{
            zIndex: 1000,
            width:"100%",
            background: "rgba(22, 22, 22, 0.9)",

          }}
        />
      )}
      <div className="description">
        {props.description[0] ? (
          <>
            <Accordion
              elevation={1}
              sx={{ position: "relative", zIndex: 2, width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ bgcolor: "#1c1c1c", color: "white" }}
              >
                <Typography className="item_name">{props.name}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  bgcolor: "#1c1c1c",
                  color: "white",
                }}
              >
                {props.description.map((d) => (
                  <span className="description_items" key={d}>
                    ⋅ {d}
                  </span>
                ))}
                <span className="set_weight">⋅ {props.weight}</span>
                <div className="actions">
                  <div className="prices">
                    <span className="price">{props.price} ₴</span>
                    <span style={{ textDecoration: "line-through" }}>
                      {props.oldPrice ? props.oldPrice + "₴" : ""}
                    </span>
                  </div>
                  <button
                    onClick={() => {
                      setAdd(true);
                      props.onAdd();
                    }}
                  >
                    {add ? <AddShoppingCartIcon fontSize="small" /> : "У кошик"}
                  </button>
                </div>
              </AccordionDetails>
            </Accordion>
          </>
        ) : (
          <h4 style={{ fontSize: 14, textAlign: "center" }}>{props.name}</h4>
        )}
      </div>
      <div className="actions">
        <div className="prices">
          <span className="price">{props.price} ₴</span>
          <span className="old_price">
            {props.oldPrice ? props.oldPrice + "₴" : ""}
          </span>
        </div>
        <button
          onClick={() => {
            setAdd(true);
            props.onAdd();
          }}
        >
          {add ? (
            <span className="add">
              <AddShoppingCartIcon fontSize="small" />
            </span>
          ) : (
            <span className="add">У кошик</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default ItemCard;
