import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {row.ingredients.length !== 0 ? (
          <TableCell align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell colSpan={1}></TableCell>
        )}

        <TableCell component="th" scope="row" align="center">
          <img src={row.image} alt="" height={50} width={70} />
        </TableCell>
        <TableCell align="center">
          {row.name} {`${row.isHot ? ` (${row.isHot} 🌶️)` : ""} `}
        </TableCell>
        <TableCell align="center">{row.price} ₴</TableCell>
        <TableCell align="center">{row.count}</TableCell>
        <TableCell align="center">
          <button
            className="delete_btn_orders"
            style={{ cursor: "pointer" }}
            onClick={() => props.onDelete(row.id)}
          >
            <ClearIcon sx={{ color: "red" }} />
          </button>
        </TableCell>
      </TableRow>
      {row.ingredients.length !== 0 && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Склад
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {row.ingredients.length !== 0 &&
                      row.ingredients.map((ing, i) => (
                        <TableRow key={ing}>
                          <TableCell
                            align="left"
                            sx={{
                              borderBottom:
                                i === row.ingredients.length - 1 ? 0 : "",
                            }}
                          >
                            {ing}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default Row;
