const initialState = {
  rolls: [],
  classic_sushi: [],
  grill_rolls: [],
  futomaki: [],
  dragons: [],
  bonito: [],
  avocado_rolls: [],
  cheese_rolls: [],
  california: [],
  philadelphia: [],
  toping_souse: [],
  salad_bowley: [],
  drinks: [],
  maki: [],
  guncans: [],
  vegan: [],
  hot_rolls: [],
  no_rice: [],
  burger_sushi: [],
  weekPromo: null,
  promoItems: [],
  permPromoItems: [],
};

const data = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ROLLS":
      return {
        ...state,
        rolls: [...state.rolls, action.payload],
      };
    case "MAKI_ROLLS":
      return {
        ...state,
        maki: [...state.maki, action.payload],
      };
    case "GUNCANS_ROLLS":
      return {
        ...state,
        guncans: [...state.guncans, action.payload],
      };
    case "CLASSIC_SUSHI":
      return {
        ...state,
        classic_sushi: [...state.classic_sushi, action.payload],
      };
    case "GRILL_ROLLS":
      return {
        ...state,
        grill_rolls: [...state.grill_rolls, action.payload],
      };
    case "FUTOMAKI":
      return {
        ...state,
        futomaki: [...state.futomaki, action.payload],
      };
    case "DRAGONS":
      return {
        ...state,
        dragons: [...state.dragons, action.payload],
      };
    case "BONITO":
      return {
        ...state,
        bonito: [...state.bonito, action.payload],
      };
    case "AVOCADO_ROLLS":
      return {
        ...state,
        avocado_rolls: [...state.avocado_rolls, action.payload],
      };
    case "CHEESE_ROLLS":
      return {
        ...state,
        cheese_rolls: [...state.cheese_rolls, action.payload],
      };
    case "CALIFORNIA":
      return {
        ...state,
        california: [...state.california, action.payload],
      };
    case "PHILADELPHIA":
      return {
        ...state,
        philadelphia: [...state.philadelphia, action.payload],
      };
    case "TOPPING_SOUSE":
      return {
        ...state,
        toping_souse: [...state.toping_souse, action.payload],
      };
    case "SALAD_BOWLEY":
      return {
        ...state,
        salad_bowley: [...state.salad_bowley, action.payload],
      };
    case "VEGAN_ROLLS":
      return {
        ...state,
        vegan: [...state.vegan, action.payload],
      };
    case "HOT_ROLLS":
      return {
        ...state,
        hot_rolls: [...state.hot_rolls, action.payload],
      };
    case "NO_RICE_ROLLS":
      return {
        ...state,
        no_rice: [...state.no_rice, action.payload],
      };
    case "BURGER_SUSHI":
      return {
        ...state,
        burger_sushi: [...state.burger_sushi, action.payload],
      };
    case "DRINKS":
      return {
        ...state,
        drinks: [...state.drinks, action.payload],
      };
    case "WEEKLY_PROMO":
      return {
        ...state,
        weekPromo: action.payload,
      };
    case "PROMO_ITEMS":
      return {
        ...state,
        promoItems: [...state.promoItems, action.payload],
      };
    case "PERM_PROMO_ITEMS":
      return {
        ...state,
        permPromoItems: [...state.permPromoItems, action.payload],
      };

    default:
      return state;
  }
};

export default data;
