import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyAQM5xPLpA3yKi0vXZfgcQ5aox-uIV8Wok",
  authDomain: "sakana-admin.firebaseapp.com",
  projectId: "sakana-admin",
  storageBucket: "sakana-admin.appspot.com",
  messagingSenderId: "95491056765",
  appId: "1:95491056765:web:8775d6e3539dc80c4bfd14",
  measurementId: "G-T2NVNWEZ49",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
