import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "./header.scss";
import logo from "../../assets/logo4.png";
import basket from "../../assets/basket.svg";
import Badge from "@mui/material/Badge";
import MenuNavigation from "./MenuNavigation/MenuNavigation";
import MenuNavigation600 from "./MenuNavigation/MenuNavigation600";
import ShoppingCart from "../ShoppingCart/ShoppingCars";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const Header = () => {
  const [userWidth, setUserWidth] = useState();
  const [scrollY, setScrollY] = useState(0);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const ref = useRef(null);
  const data = createSelector(
    (state) => state.basket,
    (basket) => {
      return { count: basket.count };
    }
  );

  const { count } = useSelector(data);

  useEffect(() => {
    const handleScroll = () => {
      const newScrollY = window.scrollY;
      setIsScrollingDown(newScrollY > scrollY);
      setScrollY(newScrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollY]);


  useLayoutEffect(() => {
    const { width } = ref.current.getBoundingClientRect();
    setUserWidth(width);
  }, []);

  const headerClass = `${scrollY > 100 ? "scrolled" : ""} ${isScrollingDown ? "scrolling-down" : ""}`;

  return (
    <header ref={ref} className={headerClass}>
      <nav>
        <MenuNavigation600 />

        <div className="logo_wrapper">
          <Link to="/">
            <img src={logo} alt="logo" className="header_logo" />
          </Link>
        </div>
        <div className="nav_menu_over_600">
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "#ff0000" : "",
              };
            }}
            to="/"
          >
            Головна
          </NavLink>
          <MenuNavigation />
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "#ff0000" : "",
              };
            }}
            to="/promo"
          >
            Акції
          </NavLink>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "#ff0000" : "",
              };
            }}
            to="/paymentinfo"
          >
            Оплата та доставка
          </NavLink>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "#ff0000" : "",
              };
            }}
            to="/contacts"
          >
            Контакти
          </NavLink>
        </div>

        <button className="cart_box_button">
          <ShoppingCart data={basket} width={userWidth} />
          <span className="basket_counter">
            <Badge badgeContent={count || 0} color={"success"}></Badge>
          </span>
        </button>
      </nav>
    </header>
  );
};

export default Header;
