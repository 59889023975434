const localBasket = JSON.parse(localStorage.getItem("basket"));

const initialState = localBasket || {
  products: [],
  deliveryData: {
    delivery: false,
    city: "",
    cityPrice: "",
    street: "",
    buildNumber: "",
    buildEntrance: "",
    userName: "",
    userPhone: "",
    orderComment: "",
    personCount: 1,
    orderTime: "",
    orderTimeSelect: "",
    paymentType: "",
    cashBack: "",
    discount: "Немає",
    sticks: "",
    birthDay: "",
  },
  count: 0,
};

const basket = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ITEM_TO_CART":
      const { isHot } = action.payload;

      if (state.products.some((item) => item.item.id === action.payload.id)) {
        const arrayIndex = state.products.findIndex(
          (item) => item.item.id === action.payload.id
        );

        const newArr = state.products.map((prod, ind) =>
          ind === arrayIndex
            ? {
                ...prod,
                count: prod.count + 1,
                item: {
                  ...prod.item,
                  hotCount: isHot ? prod.item.hotCount + 1 : prod.item.hotCount,
                },
              }
            : prod
        );
        // increase counter
        const st = {
          ...state,
          count: ++state.count,
          products: newArr,
        };
        localStorage.setItem("basket", JSON.stringify(st));
        return st;
      }
      // add new item
      const stz = {
        ...state,
        count: ++state.count,
        products: [
          ...state.products,
          {
            item: { ...action.payload, hotCount: isHot ? 1 : 0 },
            count: 1,
            id: action.payload.id,
          },
        ],
      };
      localStorage.setItem("basket", JSON.stringify(stz));
      return stz;
    case "REMOVE_ITEM":
      const arrToDelete = state.products.findIndex(
        (item) => item.id === action.payload
      );
      // remove item
      const ste = {
        ...state,
        count: state.count - state.products[arrToDelete].count,
        products: state.products.filter((pr) => pr.id !== action.payload),
      };
      localStorage.setItem("basket", JSON.stringify(ste));
      return ste;
    case "ADD_ONE_ITEM":
      const arrayIndexIncr = state.products.findIndex(
        (item) => item.item.id === action.payload
      );
      const newArrIncr = state.products.map((prod, ind) =>
        ind === arrayIndexIncr ? { ...prod, count: prod.count + 1 } : prod
      );
      // add one item
      const sta = {
        ...state,
        count: ++state.count,
        products: newArrIncr,
      };
      localStorage.setItem("basket", JSON.stringify(sta));
      return sta;
    case "DELETE_ONE_ITEM":
      const arrayIndexDecr = state.products.findIndex(
        (item) => item.item.id === action.payload
      );
      if (state.products[arrayIndexDecr].count === 1) {
        // delete one item
        const st = {
          ...state,
          count: --state.count,
          products: state.products.filter((pr) => pr.id !== action.payload),
        };
        localStorage.setItem("basket", JSON.stringify(st));
        return st;
      }
      const newArrDecr = state.products.map((prod, ind) =>
        ind === arrayIndexDecr ? { ...prod, count: prod.count - 1 } : prod
      );
      const sts = {
        ...state,
        count: --state.count,
        products: newArrDecr,
      };
      localStorage.setItem("basket", JSON.stringify(sts));
      return sts;

    // delivery data

    case "DELIVERY_TYPE":
      const del = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          delivery: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(del));
      return del;
    case "CITY_SELECT":
      const city = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          city: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(city));
      return city;
    case "CITY_PRICE":
      const price = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          cityPrice: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(price));
      return price;
    case "STREET_NAME":
      const name = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          street: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(name));
      return name;
    case "BUILD_NUMBER":
      const num = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          buildNumber: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(num));
      return num;
    case "BUILD_ENTRANCE":
      const entr = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          buildEntrance: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(entr));
      return entr;
    case "USER_NAME":
      const uName = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          userName: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(uName));
      return uName;
    case "USER_PHONE":
      const ph = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          userPhone: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(ph));
      return ph;
    case "ORDER_COMMENT":
      const comm = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          orderComment: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(comm));
      return comm;
    case "PERSONS_COUNT_ADD":
      return {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          personCount: action.payload,
        },
      };
    case "PERSONS_COUNT_REMOVE":
      const persons = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          personCount: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(persons));
      return persons;
    case "ORDER_TIME":
      const time = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          orderTime: action.payload,
          orderTimeSelect: "",
        },
      };
      localStorage.setItem("basket", JSON.stringify(time));
      return time;
    case "ORDER_TIME_SELECT":
      const select = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          orderTimeSelect: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(select));
      return select;
    case "ORDER_PAYMENT_SELECT":
      const payment = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          paymentType: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(payment));
      return payment;
    case "ORDER_CASHBACK":
      const cash = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          cashBack: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(cash));
      return cash;
    case "STICK_SELECT":
      const stick = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          sticks: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(stick));
      return stick;
    case "USER_BIRTHDAY":
      const bDay = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          birthDay: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(bDay));
      return bDay;
    case "DISCOUNT_SELECT":
      const disc = {
        ...state,
        deliveryData: {
          ...state.deliveryData,
          discount: action.payload,
        },
      };
      localStorage.setItem("basket", JSON.stringify(disc));
      return disc;
    case "ORDER_POST":
      localStorage.removeItem("basket");
      return {
        ...initialState,
        count: 0,
      };

    default:
      return state;
  }
};

export default basket;
