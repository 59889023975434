import "./orderPage.scss";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Row from "./OrderPageItems.jsx";
import OrderUserData from "./OrderUserData";
import {
  removeItem,
  deliveryType,
  citySelect,
  cityPrice,
  streetName,
  buildNumber,
  buildEntrance,
  userName,
  userPhone,
  orderComment,
  personsCountAdd,
  personsCountRemove,
  orderTime,
  orderTimeSelect,
  orderPaymentSelect,
  orderCashBack,
  discountSelect,
  stickSelect,
  birthDay,
} from "../../../actions";

const OrderPage = () => {
  const orders = createSelector(
    (state) => state.basket,
    (state) => state.basket,
    (basket, delivery) => {
      return {
        basket: basket.products,
        delivery: delivery.deliveryData,
      };
    }
  );

  const orderData = useSelector(orders);

  const totalPrice = (arr) => {
    return arr.reduce((a, c) => a + +c.item.price * c.count, 0);
  };
  const total = totalPrice(orderData.basket);

  const totalWithDiscount = () =>
    orderData.delivery.discount === "Немає" ? total : total - total * 0.1;

  const final = Math.ceil(totalWithDiscount().toFixed(1));

  const deliveryPrice = (total, city) => {
    let price = "";
    switch (city) {
      case "м. Горішні Плавні": {
        if (total >= 450) {
          return (price = "Безкоштовно");
        } else {
          return (price = 50);
        }
      }
      case "Піддубне":
        if (total >= 650) {
          return (price = "Безкоштовно");
        } else {
          return (price = 60);
        }
      case "Низи":
        if (total >= 700) {
          return (price = "Безкоштовно");
        } else {
          return (price = 70);
        }
      default:
        return price;
    }
  };

  const calcDeliveryPrice = deliveryPrice(+final, orderData.delivery.city);

  const toShowPrice =
    calcDeliveryPrice > 49 ? +final + calcDeliveryPrice : +final;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onRemoveItem = (id) => {
    dispatch(removeItem(id));
  };

  const createData = (data) => {
    return {
      image: data.item.image,
      name: data.item.name,
      count: data.count,
      price: +data.item.price,
      id: data.id,
      ingredients: data.item.description,
      isHot: data.item.hotCount,
    };
  };

  const rows = orderData.basket.map((item) => {
    return createData(item);
  });

  const onDeliveryTypeSelect = (type) => {
    dispatch(deliveryType(type));
  };

  const onCitySelect = (city) => dispatch(citySelect(city));
  const onCityPrice = (price) => dispatch(cityPrice(price));
  const onStreetName = (name) => dispatch(streetName(name));
  const onBuildNumber = (number) => dispatch(buildNumber(number));
  const onBuildEntrance = (entrance) => dispatch(buildEntrance(entrance));
  const onUserName = (name) => dispatch(userName(name));
  const onUserPhone = (phone) => dispatch(userPhone(phone));

  const onOrderComment = (comment) => dispatch(orderComment(comment));

  const onPersonCount = (count) => dispatch(personsCountAdd(count));
  const onPersonCountRemove = (count) => dispatch(personsCountRemove(count));
  const onOrderTime = (time) => dispatch(orderTime(time));
  const onOrderTimeSelect = (select) => dispatch(orderTimeSelect(select));

  const onPaymentSelect = (pay) => dispatch(orderPaymentSelect(pay));
  const onStickSelect = (stick) => dispatch(stickSelect(stick));
  const onCashBack = (back) => dispatch(orderCashBack(back));
  const onDiscountSelect = (discount) => dispatch(discountSelect(discount));
  const onBirthDaySelect = (b) => dispatch(birthDay(b));

  const fallback = (
    <div className="fallback_orders">
      <h3>У Вас немає товарів для замовлення</h3>
      <button onClick={() => navigate("/")}>На головну</button>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Оформлення замовлення</title>
        <meta
          name="description"
          content="Також доставляємо в Піддубне, Низи."
        />
      </Helmet>
      <div className="order_page">
        <div className="orders_list">
          {orderData.basket.length === 0 ? (
            fallback
          ) : (
            <>
              <h3>Ваше замовлення</h3>
              <TableContainer component={Paper} className="table_items">
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Інфо</TableCell>

                      <TableCell align="center">Зобр.</TableCell>
                      <TableCell align="center">Назва</TableCell>
                      <TableCell align="center">Ціна</TableCell>
                      <TableCell align="center">К-сть</TableCell>
                      <TableCell align="center">Видалити</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <Row key={row.name} row={row} onDelete={onRemoveItem} />
                    ))}
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={4}
                        sx={{ fontSize: 18, border: 0 }}
                      ></TableCell>
                      <TableCell align="center" colSpan={0} sx={{ border: 0 }}>
                        Всього:
                      </TableCell>
                      <TableCell align="center" colSpan={0} sx={{ border: 0 }}>
                        {orderData.delivery.delivery ? toShowPrice : total} ₴
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
        {orderData.basket.length === 0 ? (
          ""
        ) : (
          <OrderUserData
            onBirthDaySelect={onBirthDaySelect}
            birthday={orderData.delivery.birthDay}
            deliveryType={onDeliveryTypeSelect}
            delivery={orderData.delivery.delivery}
            onCitySelect={onCitySelect}
            city={orderData.delivery.city}
            onCityPrice={onCityPrice}
            price={orderData.delivery.cityPrice}
            onStreetName={onStreetName}
            street={orderData.delivery.street}
            onBuildNumber={onBuildNumber}
            buildNumber={orderData.delivery.buildNumber}
            onBuildEntrance={onBuildEntrance}
            buildEntrance={orderData.delivery.buildEntrance}
            onUserName={onUserName}
            userName={orderData.delivery.userName}
            onUserPhone={onUserPhone}
            userPhone={orderData.delivery.userPhone}
            onOrderComment={onOrderComment}
            orderComment={orderData.delivery.orderComment}
            onPersonCount={onPersonCount}
            persons={orderData.delivery.personCount}
            onPersonCountRemove={onPersonCountRemove}
            onOrderTime={onOrderTime}
            orderTime={orderData.delivery.orderTime}
            onOrderTimeSelect={onOrderTimeSelect}
            timeSelect={orderData.delivery.orderTimeSelect}
            onPaymentSelect={onPaymentSelect}
            payment={orderData.delivery.paymentType}
            onCashBack={onCashBack}
            onStickSelect={onStickSelect}
            stick={orderData.delivery.sticks}
            back={orderData.delivery.cashBack}
            onDiscountSelect={onDiscountSelect}
            discount={orderData.delivery.discount}
            total={total}
            calcDeliveryPrice={calcDeliveryPrice}
            totalWithDiscount={toShowPrice}
          />
        )}
      </div>
    </>
  );
};

export default OrderPage;
