import SpeedDial from "@mui/material/SpeedDial";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import "./actionMenu.scss";
const ArrowUp = (props) => {
  const handlerOnClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <SpeedDial
      onClick={handlerOnClick}
      className="arrowUp_vis"
      ariaLabel="arrow up"
      sx={{
        position: "fixed",
        bottom: 90,
        right: 16,
        opacity: props.visible ? 1 : 0,
        transition: ".3s ease",
        zIndex: props.visible ? 1050 : -2,
      }}
      icon={<KeyboardArrowUpRoundedIcon />}
    ></SpeedDial>
  );
};
export default ArrowUp;
