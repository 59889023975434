import { useState } from "react";
import { Fragment } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import logo from "../../../assets/Logo_suschi.png";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import "./menuNavigation.scss";
import drinks from "../../../assets/drinks.svg";
import { menuLinks } from "./links";
import { Link, NavLink } from "react-router-dom";

const MenuNavigation = (props) => {
  const [state, setState] = useState({
    left: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      className="side_drawer"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      sx={{
        padding: 0,
        border: 0,
        boxSizing: "border-box",
        // height: "100%",
      }}
    >
      <div className="logo_wrapper">
        <Link to="/" className="logo_link">
          <img src={logo} alt="logo" height={115} width={115} />
        </Link>
      </div>
      <div style={{ padding: "85px 0" }}></div>
      <div className="mapped_links" style={{ paddingTop: 0 }}>
        {menuLinks.map((menu) => (
          <Fragment key={menu.name}>
            <button className="menu_button" style={{ width: "80%" }}>
              <NavLink
                to={menu.to}
                className="links_with_ico"
                style={({ isActive }) => {
                  return {
                    color: isActive ? "red" : "inherit",
                  };
                }}
              >
                {menu.name}&nbsp;{" "}
                {menu.isNew ? (
                  <span className="new" style={{ color: "black" }}>
                    New
                  </span>
                ) : null}
                <span className="ico">{menu.ico}</span>
              </NavLink>
            </button>
            <Divider />
          </Fragment>
        ))}
        <button
          style={{ width: "80%" }}
          className="menu_button"
          onClick={toggleDrawer(anchor, false)}
        >
          <NavLink
            to="/drinks"
            className="links_with_ico"
            style={({ isActive }) => {
              return {
                color: isActive ? "red" : "inherit",
              };
            }}
          >
            Напої
            <span className="ico">
              <img src={drinks} alt="" height={20} width={20} />
            </span>
          </NavLink>
        </button>
      </div>
    </Box>
  );
  return (
    <div className="button_600">
      <button
        onClick={toggleDrawer("left", true)}
        className="active-button__menu"
      >
        Меню <MenuOpenIcon />
      </button>
      <SwipeableDrawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        {list("left")}
      </SwipeableDrawer>
    </div>
  );
};

export default MenuNavigation;
