// cart items

export const addItemToCart = (id) => {
  return {
    type: "ADD_ITEM_TO_CART",
    payload: id,
  };
};

export const addOneItem = (id) => {
  return {
    type: "ADD_ONE_ITEM",
    payload: id,
  };
};
export const deleteOneItem = (id) => {
  return {
    type: "DELETE_ONE_ITEM",
    payload: id,
  };
};
export const removeItem = (id) => {
  return {
    type: "REMOVE_ITEM",
    payload: id,
  };
};

export const deleteItemFromCart = (id) => {
  return {
    type: "DELETE_ITEM_FROM_CART",
    payload: id,
  };
};

// order data

export const deliveryType = (action) => {
  return {
    type: "DELIVERY_TYPE",
    payload: action,
  };
};
export const citySelect = (action) => {
  return {
    type: "CITY_SELECT",
    payload: action,
  };
};

export const cityPrice = (action) => {
  return {
    type: "CITY_PRICE",
    payload: action,
  };
};

export const streetName = (action) => {
  return {
    type: "STREET_NAME",
    payload: action,
  };
};
export const buildNumber = (action) => {
  return {
    type: "BUILD_NUMBER",
    payload: action,
  };
};
export const buildEntrance = (action) => {
  return {
    type: "BUILD_ENTRANCE",
    payload: action,
  };
};
export const buildLevel = (action) => {
  return {
    type: "BUILD_LEVEL",
    payload: action,
  };
};
export const flatNumber = (action) => {
  return {
    type: "FLAT_NUMBER",
    payload: action,
  };
};
export const userName = (action) => {
  return {
    type: "USER_NAME",
    payload: action,
  };
};
export const userPhone = (action) => {
  return {
    type: "USER_PHONE",
    payload: action,
  };
};
export const orderComment = (action) => {
  return {
    type: "ORDER_COMMENT",
    payload: action,
  };
};
export const personsCountAdd = (action) => {
  return {
    type: "PERSONS_COUNT_ADD",
    payload: action,
  };
};
export const personsCountRemove = (action) => {
  return {
    type: "PERSONS_COUNT_REMOVE",
    payload: action,
  };
};
export const orderTime = (action) => {
  return {
    type: "ORDER_TIME",
    payload: action,
  };
};
export const orderTimeSelect = (action) => {
  return {
    type: "ORDER_TIME_SELECT",
    payload: action,
  };
};
export const orderPaymentSelect = (action) => {
  return {
    type: "ORDER_PAYMENT_SELECT",
    payload: action,
  };
};
export const orderCashBack = (action) => {
  return {
    type: "ORDER_CASHBACK",
    payload: action,
  };
};
export const discountSelect = (action) => {
  return {
    type: "DISCOUNT_SELECT",
    payload: action,
  };
};
export const stickSelect = (stick) => {
  return {
    type: "STICK_SELECT",
    payload: stick,
  };
};
export const birthDay = (d) => {
  return {
    type: "USER_BIRTHDAY",
    payload: d,
  };
};

export const orderPost = () => {
  return {
    type: "ORDER_POST",
  };
};

// menu items

export const getRolls = (rolls) => {
  return {
    type: "ADD_ROLLS",
    payload: rolls,
  };
};
export const getClassicSushi = (sushi) => {
  return {
    type: "CLASSIC_SUSHI",
    payload: sushi,
  };
};
export const getGrillRolls = (rolls) => {
  return {
    type: "GRILL_ROLLS",
    payload: rolls,
  };
};
export const getFutomaki = (dr) => {
  return {
    type: "FUTOMAKI",
    payload: dr,
  };
};
export const getDragons = (dr) => {
  return {
    type: "DRAGONS",
    payload: dr,
  };
};
export const getBonito = (b) => {
  return {
    type: "BONITO",
    payload: b,
  };
};
export const getAvocadoRolls = (rolls) => {
  return {
    type: "AVOCADO_ROLLS",
    payload: rolls,
  };
};
export const getCheeseRolls = (rolls) => {
  return {
    type: "CHEESE_ROLLS",
    payload: rolls,
  };
};
export const getCalifornia = (california) => {
  return {
    type: "CALIFORNIA",
    payload: california,
  };
};
export const getPhiladelphia = (philadelphia) => {
  return {
    type: "PHILADELPHIA",
    payload: philadelphia,
  };
};
export const getMaki = (maki) => {
  return {
    type: "MAKI_ROLLS",
    payload: maki,
  };
};
export const getGuncans = (g) => {
  return {
    type: "GUNCANS_ROLLS",
    payload: g,
  };
};
export const getSouse = (souse) => {
  return {
    type: "TOPPING_SOUSE",
    payload: souse,
  };
};
export const getSalads = (salad) => {
  return {
    type: "SALAD_BOWLEY",
    payload: salad,
  };
};
export const getDrinks = (drinks) => {
  return {
    type: "DRINKS",
    payload: drinks,
  };
};
export const getWeeklyPromo = (promo) => {
  return {
    type: "WEEKLY_PROMO",
    payload: promo,
  };
};
export const getPromoItems = (promo) => {
  return {
    type: "PROMO_ITEMS",
    payload: promo,
  };
};
export const getPermPromo = (promo) => {
  return {
    type: "PERM_PROMO_ITEMS",
    payload: promo,
  };
};
// new items

export const getVeganRolls = (vagan) => {
  return {
    type: "VEGAN_ROLLS",
    payload: vagan,
  };
};
export const getHotRolls = (rolls) => {
  return {
    type: "HOT_ROLLS",
    payload: rolls,
  };
};
export const getNoRiceRolls = (rolls) => {
  return {
    type: "NO_RICE_ROLLS",
    payload: rolls,
  };
};
export const getBurgerSushi = (rolls) => {
    return {
      type: "BURGER_SUSHI",
      payload: rolls,
    };
  };
  