import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { orderPost } from "../../../actions";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import { IMaskInput } from "react-imask";
import Input from "@mui/material/Input";
import ReactPixel from "react-facebook-pixel";

import "./orderPage.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Sticks from "../../../assets/Sticks";
import { doc, setDoc, updateDoc, increment, getDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";

import "react-phone-number-input/style.css";
import { ModalOrder } from "./OrderModal";

import { useState, useEffect } from "react";

const OrderUserData = (props) => {
  ReactPixel.init("917308052688018");

  const orders = createSelector(
    (state) => state.basket,
    (state) => state.basket,
    (basket, delivery) => {
      return {
        basket: basket.products,
        delivery: delivery.deliveryData,
      };
    }
  );

  const dispatch = useDispatch();
  const orderData = useSelector(orders);

  const orderItems = orderData.basket.map((item) => ({
    name: item.item.name,
    count: item.count,
    hot: item.item.hotCount,
  }));
  const delivery = props.calcDeliveryPrice;

  const [timeSlots, setTimeSlots] = useState([]);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState("");

  useEffect(() => {
    if (!count) {
      const countRef = doc(db, "count", "count");
      getDoc(countRef).then((res) => setCount(res.data()));
    }
  }, []);

  const onCloseModal = () => {
    setOpen(false);
    !error && dispatch(orderPost());
  };

  const onSubmit = (e) => {
    e.preventDefault(e);

    const TOKEN = "6236641717:AAH4D_5XE0Hp3ZoeL9KNhcZ86rcBMRiahu4";
    const user = "-972050282";

    const messageToSend = `Нове замовлення: ##-${count.count}-##
    \n - Доставка: ${orderData.delivery.delivery ? "Так" : "Ні"} \n - Місто: ${
      orderData.delivery.city
    } \n - Вулиця: ${orderData.delivery.street} \n - Номер будинку: ${
      orderData.delivery.buildNumber
    } \n - Під'їзд: ${orderData.delivery.buildEntrance} \n - Імʼя: ${
      orderData.delivery.userName
    } \n - Номер телефону: ${
      orderData.delivery.userPhone
    } \n - День народження: ${orderData.delivery.birthDay} \n - Коментар: ${
      orderData.delivery.orderComment
    } \n - Палички: ${orderData.delivery.sticks} \n - Персон №: ${
      orderData.delivery.personCount
    } \n - На який час: ${
      orderData.delivery.orderTime
    } \n - Заберу/доставити в : ${
      orderData.delivery.orderTimeSelect
    } \n - Спосіб оплати: ${orderData.delivery.paymentType} \n - Решта з : ${
      orderData.delivery.cashBack ? orderData.delivery.cashBack : "Без решти"
    } \n - Знижка: ${orderData.delivery.discount} \n - Всьго: ${
      props.total
    } \n - Всьго зі знижкою: ${
      orderData.delivery.delivery ? props.totalWithDiscount : props.total
    } \n - ЗАМОВЛЕННЯ: ${orderItems.map((item) => {
      return `\n Товар: ${item.name} : ${item.count} ${
        item.hot ? `(${item.hot} 🌶️)` : ""
      } `;
    })}
    `;

    if (orderData.delivery.userName && orderData.delivery.userPhone) {
      const url = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

      const orderRef = doc(db, "orders", `${Date.now()}`);
      const countRef = doc(db, "count", "count");

      setDoc(orderRef, { order: messageToSend, id: Date.now() });
      updateDoc(countRef, {
        count: increment(1),
      });

      try {
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chat_id: user,
            text: messageToSend,
          }),
        }).then((res) => {
          setError(false);
          if (!res.ok) {
            setError(
              "Вибачте, виникли технічні складності, відправте замовлення ще раз"
            );
          }
          setOpen(true);

          ReactPixel.track("Purchase", {
            currency: "UAH",
            value: orderData.delivery.delivery
              ? props.totalWithDiscount
              : props.total,
          });

          setCount("");
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      setError("Заповніть всі поля");
    }
  };

  useEffect(() => {
    const now = new Date();
    const start = new Date();
    start.setHours(10, 0, 0);
    if (now > start) {
      start.setHours(now.getHours(), now.getMinutes(), 0);
      start.setMinutes(30 * Math.ceil(start.getMinutes() / 30));
    }

    const newTimeSlots = [];
    while (start.getHours() < 21) {
      newTimeSlots.push(formatTime(start));
      start.setMinutes(start.getMinutes() + 30);
    }
    newTimeSlots.shift();
    setTimeSlots(newTimeSlots);
  }, []);

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <div className="order_user_data">
      {props.delivery && <h3 className="intro_info">Оформлення доставки</h3>}

      <div className="radio_order">
        <button
          style={
            props.delivery === false
              ? { background: "#ff0000", color: "#fff" }
              : { background: "lightgrey", color: "#000" }
          }
          onClick={() => props.deliveryType(false)}
        >
          Заберу сам
        </button>
        <button
          style={
            props.delivery === true
              ? { background: "#ff0000", color: "#fff" }
              : { background: "lightgrey", color: "#000" }
          }
          onClick={() => props.deliveryType(true)}
        >
          Доставка
        </button>
      </div>
      {props.delivery === false && (
        <>
          <form action="order" onSubmit={onSubmit}>
            <h3 style={{ padding: 5 }}>
              Aдреса самовивозу просп. Героїв Дніпра, 36
            </h3>
            <div className="form_pickup_order">
              <div className="pickup_order">
                <div className="user_pick_data">
                  <TextField
                    id="name"
                    className="width700"
                    label="Імʼя"
                    variant="standard"
                    required
                    value={props.userName}
                    onChange={(e) => props.onUserName(e.target.value)}
                  />

                  <FormControl className="width700" variant="standard">
                    <InputLabel htmlFor="formatted-text-mask-input">
                      Телефон
                    </InputLabel>
                    <Input
                      value={props.userPhone}
                      onChange={(e) => props.onUserPhone(e.target.value)}
                      name="textmask"
                      required
                      id="formatted-text-mask-input"
                      inputComponent={TextMaskCustom}
                    />
                  </FormControl>
                </div>

                <TextField
                  id="birthday"
                  className="width700"
                  label="Дата народження"
                  variant="standard"
                  value={props.birthday}
                  onChange={(e) => props.onBirthDaySelect(e.target.value)}
                />
                <h5>
                  <span style={{ color: "red" }}>*</span> для подальших акцій та
                  додаткових знижок
                </h5>
              </div>
              <div className="time">
                <div className="time-select-pickup">
                  <RadioGroup
                    className="pickup_time_select"
                    row
                    aria-labelledby="time"
                    name="position"
                    value={props.orderTime || "ЯКОМОГА ШВИДШЕ"}
                    onChange={(e) => props.onOrderTime(e.target.value)}
                  >
                    <FormControlLabel
                      value="ЯКОМОГА ШВИДШЕ"
                      control={<Radio />}
                      label="ЯКОМОГА ШВИДШЕ"
                      labelPlacement="top"
                    />
                    <FormControlLabel
                      value="ДО ПЕВНОГО ЧАСУ"
                      control={<Radio />}
                      label="ДО ПЕВНОГО ЧАСУ"
                      labelPlacement="top"
                    />
                  </RadioGroup>
                </div>

                {props.orderTime === "ДО ПЕВНОГО ЧАСУ" && (
                  <div className="time_select">
                    <FormControl
                      variant="standard"
                      sx={{
                        justifyContent: "center",
                        minWidth: 160,
                        margin: "0 auto",
                      }}
                      className="time_select_item"
                    >
                      <InputLabel id="time">Виберіть годину</InputLabel>
                      <Select
                        labelId="time"
                        id="time_select"
                        value={props.timeSelect || ""}
                        defaultValue=""
                        onChange={(e) =>
                          props.onOrderTimeSelect(e.target.value)
                        }
                        label="Виберіть годину"
                      >
                        {timeSlots.map((time, index) => (
                          <MenuItem key={index} value={time}>
                            {time}
                          </MenuItem>
                        ))}
                        <MenuItem value="20:45">20:45</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
                <div className="select_stick_pick_up">
                  <div className="left">
                    <h3>Які палички Вам найбільше підходять?</h3>
                    <div className="stick_select">
                      <button
                        type="button"
                        className="training"
                        onClick={() => props.onStickSelect("Hавчальні")}
                      >
                        {props.stick === "Hавчальні" && (
                          <span className="check_mark">
                            <CheckCircleIcon
                              fontSize="small"
                              sx={{ color: "white" }}
                            />
                          </span>
                        )}

                        <Sticks />
                        <span>Hавчальні</span>
                      </button>
                      <button
                        type="button"
                        className="normal"
                        onClick={() => props.onStickSelect("Звичайні")}
                      >
                        {props.stick === "Звичайні" && (
                          <span className="check_mark">
                            <CheckCircleIcon
                              fontSize="small"
                              sx={{ color: "white" }}
                            />
                          </span>
                        )}

                        <Sticks />
                        <span>Звичайні</span>
                      </button>
                    </div>
                  </div>
                  <div className="right">
                    <h3>Кількість приборів</h3>
                    <div className="buttons_person">
                      <button
                        type="button"
                        onClick={() =>
                          props.onPersonCountRemove(props.persons - 1)
                        }
                        disabled={props.persons === 0}
                      >
                        <RemoveIcon sx={{ color: "#fff" }} />
                      </button>
                      {props.persons}
                      <button
                        type="button"
                        disabled={props.persons === 10}
                        onClick={() => props.onPersonCount(props.persons + 1)}
                      >
                        <AddIcon sx={{ color: "#fff" }} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" className="order_button">
              Замовити
            </button>
            <ModalOrder open={open} error={error} close={onCloseModal} />
          </form>
        </>
      )}
      {props.delivery && (
        <form name="order" onSubmit={onSubmit}>
          <div className="user_data_order">
            <div className="order_data_left">
              <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <InputLabel id="city">Населенный пункт</InputLabel>
                <Select
                  labelId="city"
                  id="city_select"
                  value={props.price || ""}
                  defaultValue=""
                  onChange={(e, v) => {
                    props.onCitySelect(v.props.children);
                    props.onCityPrice(e.target.value);
                  }}
                  label="Населенный пункт"
                >
                  <MenuItem value={50}>м. Горішні Плавні</MenuItem>
                  <MenuItem value={60}>Піддубне</MenuItem>
                  <MenuItem value={70}>Низи</MenuItem>
                </Select>
              </FormControl>

              <TextField
                id="street"
                label="Вулиця"
                variant="standard"
                required
                value={props.street || ""}
                onChange={(e) => props.onStreetName(e.target.value)}
              />

              <TextField
                id="number"
                label="Номер будинку"
                variant="standard"
                required
                value={props.buildNumber || ""}
                onChange={(e) => props.onBuildNumber(e.target.value)}
              />

              <TextField
                id="entrance"
                label="Під'їзд"
                variant="standard"
                value={props.buildEntrance || ""}
                onChange={(e) => props.onBuildEntrance(e.target.value)}
              />
            </div>
            <div className="order_data_right">
              <TextField
                id="name"
                label="Імʼя"
                variant="standard"
                required
                value={props.userName}
                onChange={(e) => props.onUserName(e.target.value)}
              />

              <FormControl variant="standard">
                <InputLabel htmlFor="formatted-text-mask-input">
                  Телефон
                </InputLabel>
                <Input
                  value={props.userPhone}
                  onChange={(e) => props.onUserPhone(e.target.value)}
                  name="textmask"
                  required
                  id="formatted-text-mask-input"
                  inputComponent={TextMaskCustom}
                />
              </FormControl>

              <TextField
                id="birthday"
                label="Дата народження"
                variant="standard"
                value={props.birthday}
                onChange={(e) => props.onBirthDaySelect(e.target.value)}
              />
              <h5
                style={{
                  height: 48,
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <span style={{ color: "red" }}>*</span> для подальших акцій та
                додаткових знижок
              </h5>
              <div className="promotions">
                <h3>Знижки</h3>
{/*<SomeComponent open={openPromo} close={setOpenPromo} />*/}
                <RadioGroup
                  row
                  sx={{ justifyContent: "center" }}
                  aria-labelledby="discount"
                  name="discount"
                  className="discount_select"
                  value={props.discount || "Немає"}
                  onChange={(e) => props.onDiscountSelect(e.target.value)}
                >
                  <FormControlLabel
                    value="Немає"
                    control={<Radio />}
                    label="Немає"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="Військовий"
                    control={<Radio />}
                    label="Військовий"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="День народження"
                    control={<Radio />}
                    label="День народження"
                    labelPlacement="top"
                  />
                </RadioGroup>

                <p>
                  <span style={{ color: "red" }}>*</span> При виборі однієї зі
                  знижок Вам потрібно будє показати докумен
                </p>
              </div>
            </div>
          </div>
          <div className="time_select">
            <div className="time">
              <RadioGroup
                row
                aria-labelledby="time"
                className="delivery_time_select"
                sx={{ display: "flex", justifyContent: "center" }}
                name="position"
                value={props.orderTime || "ЯКОМОГА ШВИДШЕ"}
                onChange={(e) => props.onOrderTime(e.target.value)}
              >
                <FormControlLabel
                  value="ЯКОМОГА ШВИДШЕ"
                  control={<Radio />}
                  label="ЯКОМОГА ШВИДШЕ"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="ДО ПЕВНОГО ЧАСУ"
                  control={<Radio />}
                  label="ДО ПЕВНОГО ЧАСУ"
                  labelPlacement="top"
                />
              </RadioGroup>
              {props.orderTime === "ДО ПЕВНОГО ЧАСУ" && (
                <>
                  <FormControl
                    variant="standard"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      minWidth: 160,
                      margin: "0 auto",
                    }}
                    className="time_select_item"
                  >
                    <InputLabel id="time">Виберіть годину</InputLabel>
                    <Select
                      labelId="time"
                      id="time_select"
                      value={props.timeSelect || ""}
                      defaultValue=""
                      onChange={(e) => props.onOrderTimeSelect(e.target.value)}
                      label="Виберіть годину"
                    >
                      {timeSlots.map((time, index) => (
                        <MenuItem key={index} value={time}>
                          {time}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
              <div className="right">
                <h3>Тип оплати</h3>
                <RadioGroup
                  row
                  aria-labelledby="payment"
                  name="payment"
                  value={props.payment || "Безготівковий"}
                  onChange={(e) => props.onPaymentSelect(e.target.value)}
                >
                  <FormControlLabel
                    value="Безготівковий"
                    control={<Radio />}
                    label="Безготівковий"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="Готівка"
                    control={<Radio />}
                    label="Готівка"
                    labelPlacement="top"
                  />
                </RadioGroup>

                {props.payment === "Готівка" && (
                  <>
                    <h3>З якої суми потрібна решта</h3>
                    <TextField
                      id="apartment"
                      label="Решта"
                      variant="standard"
                      required
                      value={props.back}
                      onChange={(e) => props.onCashBack(e.target.value)}
                    />
                    <button type="button" onClick={() => props.onCashBack(0)}>
                      Без решти
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="persons">
              <h3>Які палички Вам найбільше підходять?</h3>
              <div className="stick_select">
                <button
                  type="button"
                  className="training"
                  onClick={() => props.onStickSelect("Hавчальні")}
                >
                  {props.stick === "Hавчальні" && (
                    <span className="check_mark">
                      <CheckCircleIcon
                        fontSize="small"
                        sx={{ color: "white" }}
                      />
                    </span>
                  )}

                  <Sticks />
                  <span>Hавчальні</span>
                </button>
                <button
                  type="button"
                  className="normal"
                  onClick={() => props.onStickSelect("Звичайні")}
                >
                  {props.stick === "Звичайні" && (
                    <span className="check_mark">
                      <CheckCircleIcon
                        fontSize="small"
                        sx={{ color: "white" }}
                      />
                    </span>
                  )}

                  <Sticks />
                  <span>Звичайні</span>
                </button>
              </div>

              <h3>Кількість приборів</h3>
              <div className="buttons_person">
                <button
                  type="button"
                  onClick={() => props.onPersonCountRemove(props.persons - 1)}
                  disabled={props.persons === 0}
                >
                  <RemoveIcon sx={{ color: "#fff" }} />
                </button>
                {props.persons}
                <button
                  type="button"
                  disabled={props.persons === 10}
                  onClick={() => props.onPersonCount(props.persons + 1)}
                >
                  <AddIcon sx={{ color: "#fff" }} />
                </button>
              </div>
            </div>
          </div>

          <div className="comment">
            <div className="left">
              <TextField
                sx={{ width: "100%", bgcolor: "#121212" }}
                name="comment"
                id="comment"
                value={props.orderComment}
                onChange={(e) => props.onOrderComment(e.target.value)}
                label="Коментар"
                multiline
                rows={4}
                variant="standard"
              />
            </div>
          </div>

          <div className="total">
            <h3>Сума замовлення: {props.total} ₴</h3>

            <h3>
              Доставка:&nbsp;
              {props.city && (
                <span>
                  {delivery === "Безкоштовно" ? "Безкоштовно" : delivery + " ₴"}
                </span>
              )}
            </h3>
            <h3>
              Знижки:&nbsp;
              {props.discount === "День народження"
                ? "10 %"
                : props.discount === "Військовий"
                ? "10 %"
                : "Немає"}
            </h3>
            <h3>Всього: {props.totalWithDiscount} ₴</h3>
          </div>

          <button type="submit" className="order_button">
            Замовити
          </button>
          <ModalOrder open={open} close={onCloseModal} />
        </form>
      )}
    </div>
  );
};

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+38(000) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default OrderUserData;
