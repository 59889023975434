import { useEffect, useState } from "react";
import { db } from "../../../firebase/firebase";
import { collection, getDocs } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getWeeklyPromo, getPermPromo } from "../../../actions";
import { Link } from "react-router-dom";
import "./promotionsPage.scss";
import { Helmet } from "react-helmet";

const PromotionsPage = () => {
  const dispatch = useDispatch();

  const data = createSelector(
    (state) => state.data,
    (data) => {
      return { data: data };
    }
  );

  const productsData = useSelector(data);

  useEffect(() => {
    if (productsData.data.weekPromo === null) {
      const getAllData = () => {
        getDocs(collection(db, "weekly_promo")).then((doc) =>
          doc.forEach((doc) => {
            dispatch(getWeeklyPromo(doc.data()));
          })
        );
      };
      getAllData();
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    if (
      productsData.data.permPromoItems &&
      productsData.data.permPromoItems.length === 0
    ) {
      const getAllData = () => {
        getDocs(collection(db, "perm_promo_items")).then((doc) =>
          doc.forEach((doc) => {
            dispatch(getPermPromo(doc.data()));
          })
        );
      };
      getAllData();
    } else {
      return;
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Акційні пропозиції</title>
        <meta
          name="description"
          content="Знижки іменинникам та військовим, конкурси та бонуси."
        />
      </Helmet>
      <div className="promotion">
        <h2 className="promo_logo_prom">Акції</h2>

        <WeeklyProm
          date={productsData.data.weekPromo && productsData.data.weekPromo.end}
          image={
            productsData.data.weekPromo && productsData.data.weekPromo.image
          }
        />

        <div className="promo_box">
          <h2>Наші постійні акції</h2>
          <div className="promo_box_list">
            {productsData.data.permPromoItems &&
              productsData.data.permPromoItems.map((item) => (
                <div className="prom_item" key={item.name}>
                  <div className="prom_item-image">
                    <img src={item.image} alt="promotion" />

                    {item.discount && (
                      <span className="discount">{`Знижка ${item.discount} %`}</span>
                    )}
                  </div>
                  <div className="description">
                    <h4>{item.name}</h4>
                    <span>{item.description}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export const WeeklyProm = (props) => {
  return (
    <div className="weekly-prom">
      <div className="promo_backdrop"></div>

      <img src={props.image} alt="promotion" />
      <Timer date={props.date} info />
      <div className="buttons">
        <Link to="/promo/weekly_promo">Деталі акції</Link>{" "}
      </div>
    </div>
  );
};

export const Timer = (props) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(props.date) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const addZero = (num) => {
    return num === 0 ? "00" : num <= 9 ? "0" + num : num;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="timer">
      {props.info && <h4>Акція закінчується через:</h4>}

      <div className="num">
        <div className="day">
          <span className="number">{addZero(timeLeft.days)} </span>
          <span>
            {timeLeft.days === 1
              ? "День"
              : timeLeft.days > 1 || timeLeft.days <= 4
              ? "Дні"
              : timeLeft.days > 4
              ? "Днів"
              : "Днів"}
          </span>
        </div>
        <div className="hour">
          <span className="number">{addZero(timeLeft.hours)}</span>
          <span className="text">Годин</span>
        </div>
        <div className="minute">
          <span className="number">{addZero(timeLeft.minutes)}</span>
          <span className="text">Хвилин</span>
        </div>
        <div className="second">
          <span className="number">{addZero(timeLeft.seconds)}</span>
          <span className="text">Секунд</span>
        </div>
      </div>
    </div>
  );
};

export default PromotionsPage;
