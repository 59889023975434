import React from "react";

const Spinner = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: "auto" }}
      width="200"
      height="198.5"
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
    >
      <g transform="translate(72 50)">
        <circle r="1" fill="#93dbe9">
          <animateTransform
            attributeName="transform"
            begin="-0.9230769230769231s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="4.43 4.43;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.9230769230769231s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(69.48 60.224) rotate(27.692)">
        <circle r="1" fill="#93dbe9" fillOpacity="0.923">
          <animateTransform
            attributeName="transform"
            begin="-0.8461538461538461s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="4.43 4.43;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.8461538461538461s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(62.497 68.106) rotate(55.385)">
        <circle r="1" fill="#93dbe9" fillOpacity="0.846">
          <animateTransform
            attributeName="transform"
            begin="-0.7692307692307693s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="4.43 4.43;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.7692307692307693s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(52.652 71.84) rotate(83.077)">
        <circle r="1" fill="#93dbe9" fillOpacity="0.769">
          <animateTransform
            attributeName="transform"
            begin="-0.6923076923076923s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="4.43 4.43;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.6923076923076923s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(42.199 70.57) rotate(110.77)">
        <circle r="1" fill="#93dbe9" fillOpacity="0.692">
          <animateTransform
            attributeName="transform"
            begin="-0.6153846153846154s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="4.43 4.43;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.6153846153846154s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(33.533 64.589) rotate(138.462)">
        <circle r="1" fill="#93dbe9" fillOpacity="0.615">
          <animateTransform
            attributeName="transform"
            begin="-0.5384615384615384s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="4.43 4.43;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.5384615384615384s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(28.64 55.265) rotate(166.154)">
        <circle r="1" fill="#93dbe9" fillOpacity="0.538">
          <animateTransform
            attributeName="transform"
            begin="-0.46153846153846156s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="4.43 4.43;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.46153846153846156s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(28.64 44.735) rotate(193.846)">
        <circle r="1" fill="#93dbe9" fillOpacity="0.462">
          <animateTransform
            attributeName="transform"
            begin="-0.38461538461538464s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="4.43 4.43;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.38461538461538464s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(33.533 35.411) rotate(221.538)">
        <circle r="1" fill="#93dbe9" fillOpacity="0.385">
          <animateTransform
            attributeName="transform"
            begin="-0.3076923076923077s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="4.43 4.43;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.3076923076923077s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(42.199 29.43) rotate(249.23)">
        <circle r="1" fill="#93dbe9" fillOpacity="0.308">
          <animateTransform
            attributeName="transform"
            begin="-0.23076923076923078s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="4.43 4.43;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.23076923076923078s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(52.652 28.16) rotate(276.923)">
        <circle r="1" fill="#93dbe9" fillOpacity="0.231">
          <animateTransform
            attributeName="transform"
            begin="-0.15384615384615385s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="4.43 4.43;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.15384615384615385s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(62.497 31.894) rotate(304.615)">
        <circle r="1" fill="#93dbe9" fillOpacity="0.154">
          <animateTransform
            attributeName="transform"
            begin="-0.07692307692307693s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="4.43 4.43;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.07692307692307693s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(69.48 39.776) rotate(332.308)">
        <circle r="1" fill="#93dbe9" fillOpacity="0.077">
          <animateTransform
            attributeName="transform"
            begin="0s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="4.43 4.43;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="0s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
    </svg>
  );
};

export default Spinner;
