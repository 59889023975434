import "./shoppingCart.scss";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import RemoveIcon from "@mui/icons-material/Remove";
import { Link } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

const CartItems = (props) => {
  ReactPixel.init("917308052688018");

  const createData = (item) => {
    return {
      image: item.item.image,
      name: item.item.name,
      price: item.item.price,
      count: item.count,
      id: item.id,
      isHot: item.item.hotCount,
    };
  };
  const arr = props.items.map((item) => createData(item));

  const totalPrice = (arr) => {
    return arr.reduce((a, c) => a + c.price * c.count, 0);
  };

  const total = totalPrice(arr);

  const startOrder = () => {
    ReactPixel.trackCustom("StartOrder", {
      action: "start_order",
    });
  };

  return (
    <>
      {arr.map((row) => (
        <TableRow
          className="cart_items"
          key={row.name}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell component="th" scope="row" align="center">
            <img src={row.image} alt="" height={30} width={55} />
          </TableCell>
          <TableCell align="center">
            {row.name}
            {row.isHot ? ` (🌶️ ${row.isHot})` : null}
          </TableCell>
          <TableCell align="center">{row.price} ₴</TableCell>
          <TableCell align="center">
            <div className="buttons_control">
              <button
                className="counter_buttons"
                onClick={() => props.deleteOneItem(row.id)}
              >
                <RemoveIcon />
              </button>
              <span>{row.count}</span>
              <button
                className="counter_buttons"
                onClick={() => props.addOneItem(row.id)}
              >
                <AddIcon />
              </button>
            </div>
          </TableCell>
          {props.width <= 600 ? null : (
            <>
              <TableCell align="center">{row.count * row.price} ₴</TableCell>
              <TableCell align="center">
                <button onClick={() => props.deleteItem(row.id)}>
                  <ClearIcon sx={{ color: "red" }} />
                </button>
              </TableCell>
            </>
          )}
        </TableRow>
      ))}

      <TableRow>
        <TableCell
          colSpan={props.width <= 600 ? 2 : 3}
          sx={{ border: 0 }}
        ></TableCell>
        <TableCell align="center" colSpan={0} sx={{ fontSize: 18, border: 0 }}>
          Всього
        </TableCell>
        <TableCell align="center" colSpan={0} sx={{ fontSize: 18, border: 0 }}>
          {total} ₴
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={props.width <= 600 ? 3 : 4}>
          <span style={{ color: "red" }}>*</span> васабі, соєвий соус та імбир
          входять у вартість
        </TableCell>
        <TableCell colSpan={0} align="center" sx={{ fontSize: 18 }}>
          <button className="create_order" onClick={() => props.onClose()}>
            <Link to="/order" onClick={startOrder}>
              Замовити
            </Link>
          </button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CartItems;
